





export default {

  components: {
    'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue')
  },

  data() {
    return {
      code: this.$route.params?.code,
      email: this.$route.params?.email
    }
  },

  async mounted() {
    if (!this.code || !this.email) {
      this.$wToast.error('Nastala chyba pri aktivácii účtu.')
    } else {
      try {
        await this.$store.dispatch('wAuth/verify', { code: this.code, email: this.email})
        // created toast on root so it would not be destroyed on route change
        this.$root.$bvToast.toast(
          'Možete sa prihlásiť s údajmi, ktoré ste zadali pri registrácii.', 
          {
            title: 'Verifikácia prebehla úspešne!',
            autoHideDelay: 5000,
            variant: 'a-success',
            toaster: 'b-toaster-top-center',
            solid: true
          }
        )
      } catch(err) {
        this.$root.$wToast.error(err)
      }
    }
    
    await this.$router.push({ name: 'Home'})
  }
}
